html {
  background-color: #f9f9f9;
}

.new-message {
  width: 320px;
  .new-message-text {
    resize: none;
    margin-bottom: 10px;
  }
  button {
    width: 100%;
  }
}

.skin-green #main-content {
  background-color: #f9f9f9;
}

.login-page:before, .error-page:before, #main-content {
  background-color: #f9f9f9;
}

.checkbox input[type=checkbox] {
  position: relative;
  margin-right: 5px;
  margin-left: 0;
}

.form-wizard .active .step .number {
    background: #96CA50;
  }
  .form-wizard .step span.number {
    min-width: 49px;
    text-align: center;
    background-color: #000;
    color: #fff;
  }

.skin-green {

  .navbar {
    background: #96CA50;
  }
}

.advance {
  position: relative;
  .form-group {
    margin: 0;
    .form-control{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 47.33333%;
    }
    .help-block{
      position: absolute;
      bottom: 40px;
    }
  }
  .no-pos {
    position: static;
  }
  .btn-group-justified .btn {
    display: block;
    width: 100%;
    margin: 10px 0;
  }

  @media (max-width: 768px) {
    .form-group {
      .form-control {
        position: static;
        top: 0;
        width: 100%;
      }
      .help-block{
        position: static;
        bottom: none;
      }
    }
  }
}

.condition {
  .form-group input {
    text-align: center;
  }
  .control-label {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    .btn-group-justified .btn {
      display: block;
      width: 100%;
      margin: 10px 0;
    }
  }
}
.user-profile {
  a {
    color: white;
  }
}
.spinner-wrapper {
  display: block;
  min-height: 50px;
  width: 100%;
  position: relative;
  .spinner {
    border: 5px solid #bef9d3;
    border-top: 5px solid #47cf74;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flaty-nav > li > .dd-menu > a {
  position: relative;
  display: block;
  height: 40px;
  padding: 3px 10px 0!important;
  white-space: nowrap;
}

.flaty-nav > li > .dd-menu > .open {
  background-color: #37ba63;
}

.flaty-nav > li > .dd-menu > a > [class*="fa-"] {
  font-size: 17px;
  display: inline-block;
  width: 20px;
  text-align: center;
  color: #fff;
}

.flaty-nav > li > .dd-menu > a > .badge {
  position: relative;
  top: -10px;
  left: -9px;
  border-width: 2px;
  border-style: solid;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: -10px;
  border-radius: 100%;
  border-color: #37ba63 !important;
}

.navbar > .navbar-brand {
  padding-right: 0;
}
.dropdown-navbar .msg p {
  width: 210px;
  overflow-wrap: break-word;
}
.dropdown-navbar .msg {
  width: auto !important;
}
.dropdown-menu {
  display: block;
  li a {
    font-size: 14px;
    padding: 10px 2px
  }
  .box {
    margin-bottom: 0;
    .scroll {
      height: auto;
      max-height: 200px;
      overflow-x: hidden;
    }
    .box-title {
      background-color: #37ba63;
      text-shadow: none;
      padding: 0 10px 0 15px;
      font-size: 13px;
      font-weight: bold;
      text-transform: none;
      color: #fff;
      [class*="fa-"] {
        color: #fff;
        float: right;
        margin-top: 13px;
      }
    }
    .box-content {
      padding: 0;
      width: 330px;
      ul {
        padding-left: 0;
        list-style-type: none;
        li {
          padding: 0 5px;
        }
        li a {
          padding: 6px 2px;
          margin: 0;
          font-size: 12px;
          line-height: 16px;
          color: #444;
          text-shadow: none;
          display: block;
        }
        li:hover {
          background-color: #eeeff3 !important;
        }
        .msg {
          img {
            float: left;
            width: 40px;
            height: 40px;
            margin-right: 15px;
            border-radius: 100%;
          }
          .msg-actions {
            float: right;

            .fa-reply {
              font-size: 14px;
              margin-right: 5px;
              margin-top: 2px;
            }

            .fa-times {
              font-size: 17px;
            }
          }
        }
      }
      form {
        padding: 10px;
        .input-group {
          margin-bottom: 10px;
        }
        .select-user {
          padding: 10px;
          border: solid 1px rgb(204, 204, 204);
          margin-bottom: 10px;
          height: auto;
          max-height: 100px;
          overflow-x: hidden;
          label {
            display: block;
            line-height: 1;
            span {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .fa-user-plus {
    margin-right: 5px;
    font-size: 16px;
  }
  .btn {
    padding: 10px 16px;
  }
}

.error-page h4 > span {
  color: #47cf74;
}

/* conditions */
.s-alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.s-alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.s-alert-warning {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.s-alert-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.s-alert-wrapper {
  .s-alert-box {
    border: 1px solid transparent;
    min-width: 300px;
    .s-alert-close {
      margin-top: 17px;
      opacity: .2;
      &:before, &:after {
        background-color: #000000;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: transparent;
  color: #555;
}
.hide {
  display: none !important;
}

.margin-0 {
  margin: 0 !important;
}

.opacity-03 {
  opacity: 0.3;
}

.text-align_center {
  text-align: center;
}

.margin-top_20 {
  margin-top: 20px;
}
