input[type='checkbox'] {
  vertical-align: middle;
  margin: 0;
}

.repair_tile {
  height: auto;
  font-size: 14px;
  margin-bottom: 5px;
  p {
    margin: 0;
  }
  a {
    color: #ffffff;
    &:hover {
      color: rgba(255,255,255,.5);
    }
  }
}

.repair_title {
  font-size: 14px;
  font-weight: 600;
}

.repair_comment {
  margin-top: 20px;
  max-width: 100%;
}

.repair_checkbox {
  padding-top: 13px !important;
}

.repair_buttons {
  margin-top: 20px;
}

.repairs_users {
  .user_card_wrap {
    margin-top: 15px;
  }
}

.button_valid {
  margin-top: 20px;
}