.control-label {
  margin-left: -15px;
  margin-bottom: 5px;
  font-size: 14px;
}

.no_revenue {
  text-align: center;
  font-size: 16px;
}

.margin-right_30 {
  margin-right: 30px;
}