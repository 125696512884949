.date {
    position: relative;
    .rdt {
        background-color: #fff;
        .form-control {
            position: relative;
            z-index: 2;
            background-color: transparent;
        }
        input[disabled] {
            background-color: #eee;
            z-index: 0;
        }
    }
    .icon-calendar {
        position: absolute;
        right: 12px;
        top: 10px;
        color: #888;
    }
}