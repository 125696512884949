.tab_button {
  position: relative;
  outline: none !important;
  button {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    outline: none !important;
  }
}