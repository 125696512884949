.reports {
  .input-group {
    width: 100%;
  }

  .reports_tile {
    padding: 0;
    button {
      background-color: inherit;
      font-weight: 700;
      border: 0;
      width: 100%;
      height: 100%;
      &:disabled {
        opacity: 0.8;
      }
    }
  }

  .no_inf {
    color: #F62C2C;
    margin-top: 10px;
  }
}