@media (max-width: 768px) {
    #user_info {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .navbar{
        height: auto;
        .navbar-brand{
            max-width: none;
            width: 100%;
            text-align: center;
            font-size: 17px;
        }
        .flaty-nav{
            text-align: center;
            width: 100%;
        }
    }
}
