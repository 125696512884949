.upload-img {
    img {
        display: block;
        margin: 0 auto 5px;
    }
    .btn {
        padding: 6px 9px;
    }
    input[type=file] {
        opacity: 0;
        visibility: hidden;
    }
}