.tile {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  .glyphicon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
  }
}

.tile-white {
  background-color: #FFFFFF;
  border: 2px solid #31a221;
  color: #000000;
  font-size: 20px;
}

.progress-action {
  width: 150px;
  margin: 0 auto;
}

.link-action:hover {
    .progress-bar {
      animation: progress-bar-stripes 2s linear infinite;
    }
}

.quantity-delay-block {
  font-size: 12px;
}