.payment {
    .form-group {
        margin-bottom: 0;
    }
    .btn-group-payment {
        margin: 3px 0 15px 0;
    }
    .amount.controls {
        padding: 0 15px;
    }
    .amount_date {
        padding-right: 15px;
    }
    .date_wrap {
        margin-top: 6px;
        &>label {
            text-align: left;
            padding-left: 30px;
        }
    }
}

.paygroup-error {
    color: red;
}

@media screen and (max-width: 991px) {
    .payment {
        .amount_date {
            padding-left: 15px;
            margin-top: 5px;
        }
    }
}

@media (max-width: 767px) {
    .payment {
        .btn-group-payment {
            margin-top: 0px;
            .btn {
                display: block;
                width: auto;
                margin: 3px 0;
            }
        }
    }
}