html.template_GR2 {
  
  .tile-white {
    border: 2px solid #007BA4;
	}

	.navbar {
        background: #007BA4 !important;
	}
	
	 .box > .box-title {
    background-color: #007BA4;
    color: white;
	text-shadow: none;
	}
	
	.btn {
    background-color: #007BA4;
	    color: white;
	}

	
	

	.btn:hover, .btn:active, .btn-group.open .btn.dropdown-toggle, .btn.active {
    background-color: #4ca4ee !important;
}



.progress-bar-success {
    background-color: #007BA4;
}

.flaty-nav > li > a > .badge {
     border-color: #007BA4;
}


.flaty-nav:hover{
     border-color: #4ca4ee;
}

.tile-green {
    border: 2px solid #007BA4 !important;
}

.user_card_wrap {
    border: 1px solid #007BA4;
	}


.user_card_wrap:hover {
    background-color: white;
	}
	
		
	.panel-success>.panel-heading {
    color: white;
    background-color: #007BA4;
    border-color: #007BA4;
}
	
	
	.panel-success {
    border-color: #007BA4;
}


.dropdown-menu .box .box-title {
    background-color: #007BA4;
	
	}
	
	.skin-green .dropdown-navbar > li.nav-header {
    background-color: #007BA4;
}


.dropdown-navbar > li.nav-header {
    background-color: #007BA4;
}

.nav .flaty-nav .pull-right{
	    background-color: #007BA4;
} 
.nav .flaty-nav .pull-right:hover{
	    background-color: #007BA4;
} 


.dropdown-navbar > li.nav-header {
    background-color: #007BA4;
}

 .navbar-btn:hover {
    background-color: #4ca4ee !important;
}
.flaty-nav > li:hover{
    background-color: #4ca4ee !important;
}
  
}