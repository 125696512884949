.file_upload {
  font-size: 72px;
  text-align: center;
  color: #15b74e;
  label {
    cursor: pointer;
  }
  input[type=file] {
    display: none;
  }
}