@media (max-width: 767px) {
    .controls {
        .btn-group {
            margin-top: 0;
            .btn {
                display: block;
                width: auto;
                margin: 3px 0;
            }
        }
    }
}

.btn-group-column {
    margin-top: 0;
    .btn {
        display: block;
        width: auto;
        margin: 3px 0;
    }
}

.controls {
    .btn-group {
        .btn {
            &.disabled {
                background: #ebebeb !important;
                &.active {
                    background: #dcdcdc !important;
                }
            }
        }
    }
}