@media (max-width: 480px) {
    .navbar {
        .nav {
            padding: 0;
            .user-profile {
                .fa-caret-down {
                    display: none;
                }
            }
        }
    }
}

.flaty-nav {
    .nav-user-photo {
        max-height: 41px !important;
        max-width: none !important;
    }
}