.user_card_wrap {
  border: 1px solid #31a221;
  width: 100%;
  padding: 15px;
  position: relative;
  cursor: pointer;
  background: #ffffff;
  margin-top: 10px;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
  &:hover, &.active {
    background: #e9f7ee;
  }
  img {
    height: auto;
    max-width: 40%;
    max-height: 100px;
  }
  p {
    display: inline-block;
    vertical-align: middle;
    max-width: 48%;
    margin: 0 0 0 15px;
    font-size: 24px;
  }
  .user_card_ok {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 32px;
    color: #31a221;
  }
  .user_card_none {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 32px;
    color: #b9231f;
  }
}